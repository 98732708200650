import React from "react";
// @mui
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  RadioGroup, Stack, Switch,
  TextField, Tooltip,
  Typography
} from "@mui/material";
import Select from "@mui/material/Select";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import ShortTextIcon from "@mui/icons-material/ShortText";
import NotesIcon from "@mui/icons-material/Notes";
import NumbersIcon from "@mui/icons-material/Numbers";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContrastIcon from "@mui/icons-material/Contrast";
import PasswordIcon from "@mui/icons-material/Password";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import StarIcon from '@mui/icons-material/Star';
import FilterIcon from '@mui/icons-material/Filter';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TableViewIcon from '@mui/icons-material/TableView';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';

// eslint-disable-next-line import/no-extraneous-dependencies
import {MuiColorInput} from 'mui-color-input'
import {getLabel} from "./parseLangs";
import {IntegrationQuestionType} from "../../models/integration-question-type";
import {Country, CountryList} from "../../models/country";

export const renderDateInput = (
  update: (id: string, value: any) => void,
  id: string,
  value: string | null = null,
  label: string = 'Default date',
  sx?: any
) => (
  <FormControl fullWidth sx={{mb: 2}}>
    <DatePicker
      label={label}
      value={value}
      onChange={(newValue) => update(id, newValue)}
      disableFuture
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: 1,
        ...sx
      }}/>
  </FormControl>
)

export const renderTypeOrIcon = (questionType: string) => {
  const sx = {opacity: 0.65,};
  switch (questionType) {
    case 'TEXT':
      return (<ShortTextIcon sx={sx}/>);
    case 'LONGTEXT':
      return (<NotesIcon sx={sx}/>);
    case 'NUMBER':
      return (<NumbersIcon sx={sx}/>);
    case 'RATING_NUMBER':
      return (<NumbersIcon sx={sx}/>);
    case 'RADIO':
      return (<RadioButtonCheckedIcon sx={sx}/>);
    case 'MULTIPLECHOICE':
      return (<LibraryAddCheckIcon sx={sx}/>);
    case 'MULTIPLECHOISE_IMAGE':
      return (<FilterIcon sx={sx}/>);
    case 'CONSENT':
      return (<CheckBoxIcon sx={sx}/>);
    case 'SELECT':
      return (<ArrowDropDownCircleIcon sx={sx}/>);
    case 'DATE':
      return (<CalendarMonthIcon sx={sx}/>);
    case 'BOOLEAN':
      return (<ContrastIcon sx={sx}/>);
    case 'PASSWORD':
      return (<PasswordIcon sx={sx}/>);
    case 'RATING_EMOJI':
      return (<EmojiEmotionsIcon sx={sx}/>);
    case 'EMAIL':
      return (<EmailIcon sx={sx}/>);
    case 'CONTACT':
      return (<PhoneIcon sx={sx}/>);
    case 'RATING_STAR':
      return (<StarIcon sx={sx}/>);
    case 'MULTI_QUESTION_MATRIX':
      return (<TableViewIcon sx={sx}/>)
    case 'POINT_SYSTEM':
      return (<DonutLargeIcon sx={sx}/> )
    case 'PRIORITY_LIST':
      return (<FormatListNumberedIcon sx={sx}/>)
    default:
      return (<></>);
  }
}
const parseText = (text: string) => {
  const language = localStorage.getItem('lang') || 'en';
  try {
    return JSON.parse(String(text))[language] || text;
  } catch (e) {
    return text;
  }
}
export const renderOptions = (
  options: string[] | any[],

) => options.map((option: any, index) => (
    <MenuItem key={index} value={option.id || option} sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }}>
      {
        option.id && option.type && <ListItemIcon>
          {renderTypeOrIcon(option.id)}
        </ListItemIcon>
      }
      <ListItemText primary={
        parseText(option.name) ||
        option.name || option
      } primaryTypographyProps={{
        fontSize: '14px',
        fontWeight: 400,
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}/>
    </MenuItem>
  )
)

export const renderMultipleChoise = (
  value: any,
  update: (id: string, value: any) => void,
  id: string,
  label: string,
  defaultValue: string,
  values: string[] | any[],
  multiple: boolean = false,
  sx?: any
) => (
    <FormControl fullWidth>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        multiple={multiple}
        labelId={label}
        id={label}
        value={value || defaultValue}
        label={label}
        onChange={(event) => update(id, event.target.value)}
        sx={{
          '& .MuiSelect-select': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
          ...sx
        }}
      >
        {
          renderOptions(values)
        }
      </Select>
    </FormControl>
  )

export const renderInput = (
  value: any,
  update: (id: string, value: any) => void,
  id: string,
  label: string,
  defaultValue: any,
  type: 'text' | 'number' | 'password' | 'email',
  multiline: boolean = false,
  placeholder: string = '',
  sx?: any,
  disabled: boolean = false,
  enterAction?: () => void,
  lines?: number
) =>{
  const getMaxLength = () => {
    switch (type) {
      case 'number':
        return 10;
      case 'text':
        if (id === 'assets.extraOptionText') return 15;
        return 1000000;
      default:
        return 1000;
    }
  }

  return (
      <FormControl fullWidth>
        <TextField
          autoComplete="chrome-off"
          inputProps={{
            autoComplete: type === 'password' ? 'new-password' : 'off',
            maxLength: getMaxLength()
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && enterAction) {
              enterAction()
            }
          }}
          multiline={multiline}
          maxRows={lines || multiline ? 5 : 1}
          rows={multiline ? 5 : 1}
          id={id}
          label={label}
          type={type}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          placeholder={placeholder}
          value={value || defaultValue}
          onChange={(event) => update(
            id,
            type === 'number' ? Number(event.target.value) : event.target.value
          )}
          disabled={disabled}
          sx={{
            // eslint-disable-next-line no-nested-ternary
            minHeight: lines ? lines * 45 : multiline ? 100 : 45,
            ...sx
          }}
        />
      </FormControl>
    )
}



export const renderCheckboxBoolean = (
  update: (id: string, value: any) => void,
  id: string,
  label: string,
  checked: boolean,
  sx?: any
) => (
  <FormControlLabel
    sx={{
      ml: 0.1,
      mb: 1
    }}
    control={
      <Checkbox id={id}
                checked={checked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  update(id, event.target.checked);
                }}
                sx={{
                  ...sx
                }}
      />
    }
    label={label}/>
)

export const renderCheckboxList = (
  update: (id: string, value: any) => void,
  id: string,
  label: string,
  values: string[],
  sx?: any
) => (
  <FormControl fullWidth>
    <InputLabel id={label}>{label}</InputLabel>
    <RadioGroup
      aria-label={label}
      name={label}
      value={values}
      onChange={(event) => update(id, event.target.value)}
      sx={{
        ...sx
      }}
    >
      {
        values.map((value: string, index) => (
          <FormControlLabel
            key={index}
            value={value}
            control={<Checkbox/>}
            label={value}
          />
        ))
      }
    </RadioGroup>
  </FormControl>
)

export const renderSwitchButton = (
  id: string,
  label: string,
  checked: boolean,
  update: (id: string, value: any) => void,
  icon?: any,
  sx?: any,
  helperText?: string
) => (
  <FormControl sx={{maxWidth: '90%'}}>
    <Stack direction="row" alignItems="center" sx={{...sx}}>
      <Switch checked={checked}
              inputProps={{'aria-label': 'controlled'}}
              onChange={
                (event: React.ChangeEvent<HTMLInputElement>) => update(id, event.target.checked)
              }
      />
      {
        icon && icon
      }
      <Typography sx={{
        maxWidth: '80%',
        overflow: 'visible',
        whiteSpace: 'nowrap',
      }}>
        {label}
      </Typography>
      {
        helperText &&
        <Tooltip title={helperText}>
          <HelpOutlineIcon sx={{
            ml: 0.5,
            opacity: 0.65,
            height: '22px',
            width: '20px',
            cursor: 'pointer'
          }}/>
        </Tooltip>
      }
    </Stack>
  </FormControl>
)

export const renderColorPicker = (
  id: string,
  value: string,
  update: (id: string, value: any) => void,
  label?: string,
  sx?: any
) => (
  <FormControl fullWidth sx={sx}>
    {label && <InputLabel id={label} variant='outlined' shrink>{label}</InputLabel>}
    <MuiColorInput
      value={value}
      onChange={(color: any) => update(id, color)}
      adornmentPosition="end"
      format='hex'
    />
  </FormControl>
)

export const renderCountriesPicker = (
  value: string,
  label: string,
  update: (value: any) => void,
  multiple: boolean = false,
  sx?: any,
) => (
    <FormControl fullWidth>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        multiple={multiple}
        labelId={label}
        id={label}
        value={value || 'en'}
        label={label}
        onChange={(event) => update(event.target.value)}
        sx={{
          '& .MuiSelect-select': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
          ...sx
        }}
      >
        {
          Object.values(Country).map((option: any, index: number) => (
            <MenuItem key={index} value={option.toLowerCase()} sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
              <ListItemText primary={CountryList[option.toUpperCase() as Country]}
                            primaryTypographyProps={{
                              fontSize: '14px',
                              fontWeight: 400,
                              width: '100%',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}/>
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )

export const renderLanguagePicker = (
  options: string[],
  value: string,
  label: string,
  update: (value: any) => void,
  multiple: boolean = false,
  sx?: any,
) => (
  <FormControl fullWidth>
    <InputLabel id={label}>{label}</InputLabel>
    <Select
      multiple={multiple}
      labelId={label}
      id={label}
      value={value}
      label={label}
      onChange={(event) => update(event.target.value)}
      sx={{
        '& .MuiSelect-select': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        ...sx
      }}
    >
      {
        options.map((option: string, index) => (
          <MenuItem key={index} value={option} sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}>
            <ListItemText primary={getLabel(option)} primaryTypographyProps={{
              fontSize: '14px',
              fontWeight: 400,
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}/>
          </MenuItem>
        ))
      }
    </Select>
  </FormControl>
)
