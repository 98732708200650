import React, { useCallback, useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  ListItemText,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
// theme

import { format } from 'date-fns';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { paper } from 'src/theme/css';
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';
import { useSnackbar } from 'src/components/snackbar';
import Iconify from '../../iconify';
//
import { useFeedbackContext } from '../context';
import { Feedback, IQuestions } from '../../../models/feedback';
import { getStatusImage, renderElementLink, renderThumbnailIconAndText } from '../../dataTable/new-utils';
import { FeedbackExtendedDetails } from './feedback-extended-details';
import { useAuthContext } from '../../../auth/hooks';
import { useDoubleClick } from '../../../hooks/use-double-click';
import { deleteFeedbackById, getInsightById, reSendFeedbackToProcess } from '../../../services/feedback';
import Scrollbar from '../../scrollbar/scrollbar';

import { ConfirmDialog } from '../../custom-dialog';
import { useBoolean } from '../../../hooks/use-boolean';
import EmptyContent from '../../empty-content';
import { LoadingScreen } from '../../loading-screen';

//


// ----------------------------------------------------------------------

export default function FeedbackDrawer() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const { copy } = useCopyToClipboard();
  const { open, onClose, selectedFeedback, showQuestions, refreshPage } = useFeedbackContext();
  const confirm = useBoolean(false);
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [feedback, setFeedback] = useState<Feedback | null>(selectedFeedback);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [expandedSummary, setExpandedSummary] = useState(true);

  const margins = { pr: 1, pl: 2 };
  const datesStyle = { mt: 2, ml: 1, mr: 1, color: theme.palette.text.disabled };

  const onCopy = useCallback(
    (text: string) => {
      if (text) {
        enqueueSnackbar(t('text.success.copied'), { variant: 'success' });
        copy(text);
      }
    },
    [copy, enqueueSnackbar, t],
  );

  const handleClick = useDoubleClick({
    doubleClick: () => feedback?.id && onCopy(feedback.id),
  });

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ ...margins, py: 2 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {selectedFeedback?.name}
      </Typography>

      <IconButton onClick={onClose}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  const reSendFeedback = () => {
    if (selectedFeedback?.id) {
      enqueueSnackbar(t('text.labels.resendingFeedback'), { variant: 'info' });
      reSendFeedbackToProcess(selectedFeedback?.id).then((res) => {
        if (res) {
          enqueueSnackbar(t('success.resendingFeedback'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('error.resendingFeedback'), { variant: 'error' });
        }
      });
    }
  };

  const deleteFeedback = () => {
    if (selectedFeedback?.id) {
      setSendLoading(true);
      enqueueSnackbar(t('text.labels.deleteFeedback'), { variant: 'info' });
      deleteFeedbackById(selectedFeedback?.id).then((res) => {
        if (res) {
          enqueueSnackbar(t('success.deleteFeedback'), { variant: 'success' });
          confirm.onFalse();
          refreshPage(true);
          onClose();
        } else {
          enqueueSnackbar(t('error.deleteFeedback'), { variant: 'error' });
        }
        setSendLoading(false);
      });
    }
  };

  const renderLink = selectedFeedback && (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      pb={1.5}
      sx={margins}>
      {renderElementLink(selectedFeedback.externalUrl)}
    </Stack>
  );

  const renderDetailsList = selectedFeedback && (
    <List sx={{ width: '100%' }}>
      {
        user?.dataUser?.role === 'ADMIN' && (
          <ListItem key="id" alignItems="flex-start" onClick={handleClick} sx={{
            cursor: 'pointer',
          }}>
            <ListItemText primary={t('text.tables.id')} sx={{ pr: 1 }} />
            <Typography variant="body2"
                        sx={{
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                        }}
            >{selectedFeedback?.id}</Typography>
          </ListItem>
        )
      }
      <ListItem key="identity" alignItems="center" sx={{ justifyContent: 'center' }}>
        <ListItemText primary={t('text.tables.type')} />
        {renderThumbnailIconAndText(selectedFeedback?.identity, selectedFeedback?.identity)}
      </ListItem>
      <ListItem id="status" alignItems="center" sx={{ justifyContent: 'center' }}>
        <ListItemText primary={t('text.tables.status')} />
        {getStatusImage(selectedFeedback?.status, true)}
      </ListItem>
      <ListItem id="user" alignItems="center" sx={{ justifyContent: 'center' }}>
        <ListItemText primary={t('text.tables.creator')} />
        <Typography variant="body2">{feedback?.user?.name || selectedFeedback?.user?.email}</Typography>
      </ListItem>
      {
        user?.dataUser?.role === 'ADMIN' && (
          <ListItem id="delete" alignItems="center"
                    sx={{ justifyContent: 'end' }}>
            <Button onClick={reSendFeedback}
                    variant="text"
                    startIcon={<RotateLeftIcon />}
                    sx={{
                      mt: 2,
                      mr: 2,
                      p: 1,
                      textTransform: 'none',
                      fontSize: '0.75rem',
                      fontWeight: 'bold',
                    }}>{t('text.buttons.regenerate')}</Button>
            <Button onClick={confirm.onTrue}
                    color="error"
                    variant="text"
                    startIcon={<DeleteIcon />}
                    sx={{
                      color: '#dc0000',
                      mt: 2,
                      ml: 2,
                      p: 1,
                      textTransform: 'none',
                      fontSize: '0.75rem',
                      fontWeight: 'bold',
                    }}>{t('text.buttons.delete')}</Button>
          </ListItem>
        )
      }
    </List>
  );

  const renderDetailsDates = selectedFeedback && (
    <Stack direction="column" alignItems="start" justifyContent="start" width="100%">
      <Typography variant="body2" sx={datesStyle}>{t('text.labels.addedOn')}
        {selectedFeedback?.createdAt && format(new Date(selectedFeedback?.createdAt), 'dd MMM yyyy')} at {selectedFeedback?.createdAt && format(new Date(selectedFeedback?.createdAt), 'p')}</Typography>
      <Typography variant="body2" sx={datesStyle}>{t('text.labels.mostResentChange')}
        {selectedFeedback?.updatedAt && format(new Date(selectedFeedback?.updatedAt), 'dd MMM yyyy')} at {selectedFeedback?.updatedAt && format(new Date(selectedFeedback?.updatedAt), 'p')}</Typography>
      <Typography variant="body2" sx={datesStyle}>{t('text.labels.lastUpdateExternal')}
        {selectedFeedback?.externalUpdatedAt && format(new Date(selectedFeedback?.externalUpdatedAt), 'dd MMM yyyy')} at {selectedFeedback?.externalUpdatedAt && format(new Date(selectedFeedback?.externalUpdatedAt), 'p')}</Typography>
    </Stack>
  );

  const renderDetail = (
    <Stack alignItems="center" sx={{ ...margins, mb: 2 }}>
      <Accordion expanded={expanded}
                 onChange={() => setExpanded((prevState) => !prevState)}
                 sx={{
                   width: '100%',
                   background: theme.palette.background.paper,
                   borderRadius: '16px',
                   border: `1px solid ${theme.palette.divider}`,
                 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="feedback-details-content"
          id="feedback-details-header"
          sx={{ borderBottom: `1px solid ${theme.palette.divider}`, maxHeight: '48px' }}
        >
          <Typography
            variant="subtitle2"
          >
            {t('text.labels.details')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {renderDetailsList}
        </AccordionDetails>
        <AccordionActions
          sx={{
            borderTOP: `1px solid ${theme.palette.divider}`,
            background: theme.palette.background.neutral,
          }}
        >
          {renderDetailsDates}
        </AccordionActions>
      </Accordion>
    </Stack>
  );

  const renderQuestions = (
    <Scrollbar sx={{ minHeight: 'auto', pt: 2 }}>
      {
        selectedFeedback &&
        selectedFeedback?.questions &&
        selectedFeedback.questions?.length > 0 ? selectedFeedback.questions?.map((question: IQuestions, index: number) => (
          <Stack key={index} sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Typography variant="subtitle1"
                        sx={{
                          flexGrow: 1,
                          color: theme.palette.text.secondary,
                        }}>
              {question?.title}
              {
                question.ref.includes('followup') &&
                <Tooltip title={t('text.inputs.followup')} sx={{ ml: 1 }}>
                  <AutoAwesomeIcon fontSize="small" color="primary" />
                </Tooltip>
              }
            </Typography>
            <Stack direction="column" alignItems="start" justifyContent="start" width="100%" sx={{ pb: 1 }}>
              <Typography variant="body2"
                          sx={{ mt: 2, color: theme.palette.text.secondary }}>
                {
                  selectedFeedback?.answers?.find(
                    (answer) => answer.key === question.ref,
                  )?.value?.toString()?.replace(/,/g, ', ')
                }
              </Typography>
            </Stack>
          </Stack>
        )) : (
          <EmptyContent
            filled
            title={t('error.noData')}
            sx={{
              py: 10,
            }}
          />
        )}
    </Scrollbar>
  );

  const renderOriginalText = (
    <Stack alignItems="center" sx={{ ...margins, mb: 2 }}>
      <Accordion expanded={!expanded}
                 onChange={() => setExpanded((prevState) => !prevState)}
                 sx={{
                   width: '100%',
                   background: theme.palette.background.paper,
                   borderRadius: '16px',
                   border: `1px solid ${theme.palette.divider}`,
                 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="feedback-details-content"
          id="feedback-details-header"
          sx={{ borderBottom: `1px solid ${theme.palette.divider}`, maxHeight: '48px' }}
        >
          <Typography
            variant="subtitle2"
          >
            {t('text.labels.originalText')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{
          maxHeight: '400px',
          overflow: 'auto',
          height: 'auto',
        }}>
          {
            ['MAGICFORM', 'MAGICSURVEY'].includes(feedback?.identity || '') ? renderQuestions :
              <Typography variant="body2">{selectedFeedback?.text}</Typography>
          }
        </AccordionDetails>
      </Accordion>
    </Stack>
  );

  const renderSummary = (
    <Stack alignItems="center" sx={{ ...margins, mb: 2 }}>
      <Accordion
        expanded={expandedSummary}
        onChange={() => setExpandedSummary((prevState) => !prevState)}
        sx={{
          width: '100%',
          background: theme.palette.background.paper,
          borderRadius: '16px',
          border: `1px solid ${theme.palette.divider}`,
        }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="feedback-details-content"
          id="feedback-details-header"
          sx={{ borderBottom: `1px solid ${theme.palette.divider}`, maxHeight: '48px' }}
        >
          <Typography
            variant="subtitle2"
          >
            {t('text.labels.summary')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{
          maxHeight: '400px',
          overflow: 'auto',
          height: 'auto',
        }}>
          <Typography variant="body2">{selectedFeedback?.summary}</Typography>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );

  useEffect(() => {
    console.log(selectedFeedback)
    if (selectedFeedback) {
      setLoading(true);
      getInsightById(selectedFeedback.id).then((res) => {
        if (res && res.length > 0) {
          setFeedback(res[0]);
        }
        setLoading(false);
      });
    } else {
      setFeedback(null);
    }
  }, [selectedFeedback]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: { invisible: true },
      }}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          ...paper({ theme, bgcolor: theme.palette.background.paper }),
          width: 755,
        },
      }}
    >
      {renderHead}
      {renderLink}
      {renderDetail}
      {
        ['MAGICFORM', 'MAGICSURVEY'].includes(selectedFeedback?.identity || '') ||
        selectedFeedback?.text ? renderOriginalText : <></>
      }
      {selectedFeedback?.summary && renderSummary}
      <Divider sx={{ borderStyle: 'dashed' }} />
      {loading ? <LoadingScreen/> : feedback && <FeedbackExtendedDetails feedback={feedback} showQuestions={showQuestions} />}
      <ConfirmDialog
        open={confirm.value}
        onClose={() => {
          confirm.onFalse();
          setSendLoading(false);
        }}
        title={t('text.buttons.delete')}
        label={t('text.labels.confirmationDeleteFeedback')}
        closeLabel={t('text.buttons.cancel')}
        action={
          <Button
            variant="contained"
            color="error"
            disabled={sendLoading}
            onClick={deleteFeedback}
            sx={{ backgroundColor: '#dc0000', color: '#fff' }}
          >
            {
              sendLoading ? t('text.labels.deleting') : t('text.buttons.delete')
            }
          </Button>
        }
      />
    </Drawer>
  );
}
