/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect} from "react";
import {useDataContext} from "../../components/data";
import {getIntegrationType, getIntegrationOptions, getIntegrationStatus} from "../../services/integrations";


export default function IntegrationPopover() {
  const {
    addIntegrationTypes,
    addIntegrationOptions,
    updateIntegrationOptionState
  } = useDataContext();

  const getIntegrationsTypesList = useCallback(() => {
    getIntegrationType().then(integrations => addIntegrationTypes(integrations))
  }, []);

  const getIntegrationOptionsList = useCallback(() => {
    getIntegrationOptions().then(integrations => {
      addIntegrationOptions(integrations)
    })
  }, []);

  useEffect(() => {
    getIntegrationOptionsList();
  }, []);

  return (<></>);
}
